.resume h1, p{
    color: #000;
}

.date-pill {
    border-radius: 15px;
    background-color: #3586ff;
    color: #FFF;
    font-weight: 800;
    padding: 5px 8px;
    font-size: 12px;
}

.date-pill i {
    color: #fff;
}

.company-title{
    color: #000;
    font-weight: bold;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
}

.work-card {
    // min-height: 440px;
}

.blue-icon {
    color: #3586ff;
}

.skill-block{
    background-color: #a4a3a3;
    width: 55px; 
    height: 7px;
    border-radius: 5px;
    margin-right: 3px;
}

.skill-block.filled{
    background-color: #3586ff;
}

.skill-name{
    color: #4f4e4e;
    font-size: 15px;
    font-weight: 700;
}

.work-exp-block {
	margin: 0px 0px 35px 0px;
	padding: 0 0 15px 0px;
	border-radius: 5px;
	overflow: hidden;
	min-height: 350px;
	// background: #fff;
    // border:4px solid #3586ff;
	-moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	-o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.3);
}

.work-exp-block:hover {
	-webkit-transform: translate(0, -8px);
	-moz-transform: translate(0, -8px);
	-ms-transform: translate(0, -8px);
	-o-transform: translate(0, -8px);
	transform: translate(0, -8px);
	
}

.job-title{
    border-bottom: 2px solid #3586ff;
}

.skill-card{
    border-radius: 5px;
	overflow: hidden;

	-moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	-o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.3);
}

.skill-card:hover {
	-webkit-transform: translate(0, -8px);
	-moz-transform: translate(0, -8px);
	-ms-transform: translate(0, -8px);
	-o-transform: translate(0, -8px);
	transform: translate(0, -8px);
	
}

.react-icon{
    width: 60px;
    height: 60px;
    margin: 0px 5px 0px 5px; 
}

.vue-icon{
    width: 60px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.wordpress-icon{
    width: 60px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}


.node-icon{
    width: 110px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.laravel-icon{
    width: 110px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.strapi-icon{
    width: 110px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.flutter-icon{
    width: 110px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.java-icon{
    width: 110px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.mysql-icon{
    width: 110px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.mongodb-icon{
    width: 120px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

@media (max-width: 600px) {
    .react-icon{
    width: 50px;
    height: 50px;
    margin: 0px 5px 0px 5px; 
}

.vue-icon{
    width: 50px;
    height: 50px;
     margin: 0px 5px 0px 5px; 
}

.wordpress-icon{
    width: 50px;
    height: 50px;
     margin: 0px 5px 0px 5px; 
}


.node-icon{
    width: 70px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.laravel-icon{
    width: 70px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.strapi-icon{
    width: 70px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.flutter-icon{
    width: 75px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.java-icon{
    width: 75px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.mysql-icon{
    width: 90px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

.mongodb-icon{
    width: 90px;
    height: 60px;
     margin: 0px 5px 0px 5px; 
}

    .skill-name{
        margin-bottom: 10px;
    }

    .company-title{
        text-align: center;
       padding-top: 10px;
       margin-bottom: 10px;
    }
}