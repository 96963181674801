.contact-me {
background-color: #FAFBFD;
}

.contact-me-img {
    width: 450px;
    height: auto;
    animation: moveUpDown 5s infinite linear;
}

@media (max-width: 600px) {
    .contact-me-img{ 
        width: 300px;
    }
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-15px);
    }

    100% {
        transform: translateY(0px);
    }
}

.contact-card{
    background-color: #3586ff;
    border:none;
    border-radius: 5px;
	overflow: hidden;
	-moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	-o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 6px 6px rgba(8, 11, 40, 0.3);
}