.blink {
    animation: blinkAnimation 1s steps(5, start) infinite;
}

@keyframes blinkAnimation {
    to {
        visibility: hidden;
    }
}

.stack{
    color: #545454;
    font-size: 14px;
    font-style: italic;
}

.project-title{
    color: #3586ff;
}

.info-section{
    height: 95px;
}

.project-detail-title{
    color: #000000;
    font-size: 28px;
    text-align: center !important;
    font-weight: bold;
}

.project-company{
    color: #000000;
    font-size: 17px;
    text-align: center !important;
    font-weight: 600;
    font-style: italic;
}

.project-stack-placeholder{
    color: #545454;
    font-size: 16px;
     text-align: center !important;
    font-weight: 600;
    font-style: italic;
}

.project-stack{
    color: #3586ff;   
}

.project-links{
    color: #000000;
    font-size: 16px;
      text-align: center !important;
    font-style: italic;
}

.project-description{
 color: #000000;
    font-size: 18px;
    
    font-style: italic;
}


.baseBlock {
	margin: 0px 0px 35px 0px;
	padding: 0 0 15px 0px;
	border-radius: 5px;
	overflow: hidden;
	min-height: 350px;
	background: #fff;
	-moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	-o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
}

.baseBlock:hover {
	-webkit-transform: translate(0, -8px);
	-moz-transform: translate(0, -8px);
	-ms-transform: translate(0, -8px);
	-o-transform: translate(0, -8px);
	transform: translate(0, -8px);
	
}
