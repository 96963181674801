footer {
    width: 100vw;
    margin-top: 100px;
    min-height: 100px;
    background: #3586ff;
    padding: 20px 50px 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

footer .social_icons {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
}

footer .social_icons li {
    list-style: none;
}

footer .social_icons li a{
    font-size: 2em;
    color: #fff !important;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

footer .social_icons li a:hover{
    transform: translateY(-10px);
}


footer .wave{
    position: absolute;   
    top: -100px;
    left: 0;
    height: 100px;
    width: 100%;
    background: url("../assets/wave.png");
    background-size: 1000px 100px;
}

footer .wave#wave1{
    z-index: 1;
    opacity: 0.5;
    bottom: 0;
    animation: animatedWave 5s linear infinite;
}

footer .wave#wave2{
    z-index: 2;
    opacity: 0.3;
    bottom: 10px;
    animation: animatedWave_2 2s linear infinite;
}

footer .wave#wave3{
    z-index: 3;
    opacity: 0.8;
    bottom: 10px;
    animation: animatedWave 3s linear infinite;
}

footer .wave#wave4{
    z-index: 4;
    opacity: 1;
    bottom: 10px;
    animation: animatedWave_2 4s linear infinite;
}

@keyframes animatedWave {
    0%{
        background-position-x: 1000px;
    }

    100%{
          background-position-x: 0px;
    }
}

@keyframes animatedWave_2 {
    0%{
          background-position-x: 0px;
    }
    
    100%{
        background-position-x: 1000px;
    }
    
}

.divider {
    height: 4px;
    border-radius: 50%;
    
}

.divider-white {
    background-color: #FFF;
    width: 20%;
}

.divider-blue-non-animation {
    background-color: #3586ff;
}

.divider-white-non-animation {
    background-color: #fff;
}

.divider-blue {
    background-color: #3586ff;
    width: 100%;
    animation: adjustWidth 7s ease infinite;
}

@keyframes adjustWidth {
    0% {
        width: 30%;
         height: 4px;
         border-radius: 50%;
    }

    50% {
        width: 100%;
         height: 3px;
         border-radius: 100%;
    }

    100% {
        width: 30%;
         height: 4px;
         border-radius: 50%;
    }
}

.footer-info{

}