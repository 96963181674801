:root {
  --width: 200px;
  --x-speed: 23s;
  --y-speed: 13s;
  --transition-speed: 2.2s;
  --shape-speed: 10s;
}

.hero-section{
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    filter: brightness(0.9);
    // background: radial-gradient(circle, rgba(65,227,255,1) 0%, rgba(0,161,255,1) 100%);
    background: #3586ff;
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.8);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
       
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}



.hero-info-section {
    padding: 100px;
    filter: opacity(0.85);
    border-top-right-radius:25%;
    border-bottom-left-radius:25%;
}

#typewriter span { color:red !important;   }

.info-card {
    min-width: 480px;
}

.info-card-title {
     color:black !important; font-size: 55px;
     font-family: 'Noto Serif', serif;
}

.info-card-subtitle {
    font-size: 18px;
}

.text-custom-blue {
    color: #3586ff;
}



@media (max-width: 600px) {
    .hero-info-section {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .info-card {
        min-width: 0px;
    }
}

