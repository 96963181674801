.avatar{
    width: 170px;
    height: 170px;
    border-radius: 50%;
}

.about-me{
    background-color: #0c2955;
}

.about-me h2,p,span{
    color: #FFF;
}

.resume-btn{
    padding: 13px;
    color: #3586ff;
    background-color: #FFF;
    border-radius: 25px;
    border: none;
    font-weight: bolder;
    font-style: italic;
    transition: 0.3s;
    text-decoration: none;
}

.resume-btn:hover{
    color: #FFF;
   background-color: #3586ff;
}

.resume-btn:hover i{
    animation: moveUpDownBtn 5s linear infinite;
}

@keyframes moveUpDownBtn {
    0% {
        transform: translateY(0px);
    }

     50% {
        transform: translateY(-6px);
    }

     100% {
        transform: translateY(0px);
    }
}
